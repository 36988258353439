.categoria {
    padding: 0.8rem;
    border: var(--text-color) solid 1px;
    border-radius: 5px 10px;
    margin-bottom: 1%;
}

.titulo-categoria {
    font-size: 1.2rem;
    font-style: oblique;
    font-weight: bold;
}

.location-list {
    overflow-y: scroll;
    max-height: 15rem;
}

.custom-hash-input {
    background-color: #ffcccc !important;  // Vermelho claro
    color: #b30000 !important;            // Texto vermelho escuro
    text-align: center !important;        // Centraliza o texto no input
}

